<template>
  <div>
    <div>
      <component :is="doc.template" v-for="doc in layouts" :key="doc._id" :layout="doc"></component>
    </div>
  </div>
</template>

<script>
import Legal from '@/layouts/Legal';

export default {
  computed: {
    layouts() {
      return this.$store.getters.getLayouts('Privacy');
    },
  },
  components: {
    Legal,
  },
};
</script>

<style></style>
